<template>
  <b-sidebar 
    id="sidebar-add-product"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Nouveau Produit
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
            <!-- Fournisseur -->
        <b-form-group
          label="Fournisseur"
          label-for="fourni"
        >
          <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
            v-model="fourniID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fournis"
            :clearable="false"
            input-id="fourni"
            label="NomSociete"
          />
        </b-form-group>
        <!-- Product Name -->
        <b-form-group
          label="REF Produit"
          label-for="produitref"
        >
          <b-form-input
            id="produitref"
            v-model="RefProduct"
            trim
            placeholder="#PO5892"
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Nom Produit"
          label-for="produitname"
        >
          <b-form-input
            id="produitname"
            v-model="NameProduct"
            trim
            placeholder="Ordinateur"
          />
        </b-form-group>
         <b-form-group
          label="Catégorie"
          label-for="category"
        >
          <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
            v-model="categoryID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :clearable="false"
            input-id="category"
            label="name"
          />
        </b-form-group>
        
        <b-form-group
          label="Prix d'achat"
          label-for="prixAchat"
        >
          <b-form-input
            id="prixAchat"
            v-model="prixAchat"
            trim
            placeholder="250 Dhs"
            type="number"
          />
        </b-form-group>

        <b-form-group
          label="Prix de vente"
          label-for="prixvente"
        >
          <b-form-input
            id="prixvente"
            v-model="prixVente"
            trim
            placeholder="400 Dhs"
            type="number"
          />
        </b-form-group>

         <b-form-group
          label="Qunatité"
          label-for="qnt"
        >
          <b-form-input
            id="qnt"
            v-model="qnt"
            trim
            placeholder="40"
            type="number"
          />
        </b-form-group>

         <b-form-group
          label="TVA %"
          label-for="tva"
        >
          <b-form-input
            id="tva"
            v-model="tva"
            trim
            placeholder="10%"
            type="number"
          />
        </b-form-group>
       
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addProduct()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="Reset()"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import config from '@/config'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton, vSelect,
  },
  directives: {
    Ripple,
  },
   data(){
      return{
          fournis:[],
          Products:[],
          categories:[],
          categoryID:'',
          fourniID:'',
          NameProduct:'',
          RefProduct:'',
          prixAchat:'',
          prixVente:'',
          qnt:'',
          tva:'',
          showSide:true,
          UserID:{},

      }
  },
  mounted() {
      this.$http.get(`${config.API_BASE_URL}/api/auth/getAllFournis`).then(res => { this.fournis = res.data })
      this.$http.get(`${config.API_BASE_URL}/api/auth/getCategories`).then(res => { this.categories = res.data })
      this.UserID=JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
      Reset(){
          document.getElementById("formdata").reset();
      },
       addProduct(){
            let data = new FormData;
          data.append('UserID', this.UserID.id);
          data.append('fournis_id', this.fourniID.id);
          data.append('ref_prod', this.RefProduct);
          data.append('Nom', this.NameProduct);
          data.append('category',this.categoryID.id);
          data.append('Prix_Achat', this.prixAchat);
          data.append('prix_Vente', this.prixVente);
          data.append('quantite', this.qnt);
          data.append('tva', this.tva);
          // console.log(Object.fromEntries(data))
         this.$http.post(`${config.API_BASE_URL}/api/auth/createproduct`,data)
             .then(res => { 
                if(res.data=='success'){
                    this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre produit est bien ajoutée',
                  showConfirmButton: false,
                  timer: 1000 })
                  document.getElementById("formdata").reset();
                  this.$router.go('/')
                }else{
                      this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
              })
                } })
      },
  }

}
</script>
<style scoped>
[dir] .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    border: 1px solid #0000002b !important;
    border-radius: 0.357rem;
}
.vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    border: 1px solid #0000002b !important;
    border-radius: 0.357rem;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';  
</style>
