<template>
    <div v-if="perms.includes('Produits & Stock') || perms.includes('Admin')">
     <!-- <div v-if="rows.length==0"> -->
       <div v-if="loading">
          <div id="loading-bg">
      <!-- <div class="loading-logo">
        <img src="<%= BASE_URL %>logo.png" alt="Logo" />
      </div> -->
      <div class="loading">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
      </div>
    </div>
  </div>
    <b-card v-else >
        <!-- input search -->
        <div class="custom-search">
        <b-row>
            <b-col md="3" >
             <b-form-group>
                <b-button
                variant="primary"
                v-b-toggle href="#sidebar-add-product" @click.prevent
               >
                Nouveau Produit
                <!--  v-b-toggle.sidebar-add-product -->
            </b-button>
            </b-form-group>
            </b-col>
            <b-col md="3" >
              <!-- style="margin-left: 12px;" -->
             <b-form-group > 
                <b-button
                variant="info"
                v-b-toggle href="#addstock-sidebar" @click.prevent
            >
                Nouveau Stock
            </b-button>
            </b-form-group>
            </b-col>
            <!--    <b-col md="3" >
            <b-form-group>
                <b-button
                variant="info"
                v-b-toggle.sidebar-add-product
            >
                Nouveau Stock
            </b-button>
            </b-form-group>
            </b-col> -->
            <b-col md="6">
            <b-form-group>
            <div class="d-flex align-items-center">
            <label class="mr-1">Chercher</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Chercher"
              type="text"
              class="d-inline-block"
            />
            </div>
        </b-form-group>
            </b-col>
        </b-row>
        </div>
        <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        responsive
        :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        >
        <template
            slot="table-row"
            slot-scope="props"
        >

            <!-- <div
            v-if="props.column.field === 'NumFact'"
            class="text-nowrap"
            >
            <span ># {{ props.row.NumFact }}</span>
            </div> -->
            <span
            v-if="props.column.field === 'NomSociete'"
            class="text-nowrap"
            >
            <b-avatar
            :src="`${path}${props.row.logo}`"
                class="mx-1"
            />
            <span>{{ props.row.NomSociete }}</span>
            </span>

            <!-- Column: Action -->
             <span v-else-if="props.column.field === 'stock'">
                  <b-row> <b-button style="margin-right: 4px; margin-left: 5px;"
                        variant="gradient-info"
                        class="btn-icon"
                       @click="$router.push({ name: 'voir-stock-product', params: { id: props.row.id }})"
                        >
                       <feather-icon icon="EyeIcon" />
                        
                    </b-button>
                    <b-button
                        variant="gradient-success"
                        class="btn-icon"
                         v-b-toggle href="#update-stock-sidebar" @click.prevent @click="showStock(props.row)"
                        >
                        <feather-icon icon="PlusIcon" />
                        
                    </b-button></b-row>
                     <!-- <b-row> <b-button style="margin-right: 6px;margin-left: 8px;"
                        variant="gradient-info"
                        class="btn-icon"
                        @click="$router.push({ name: 'voir-stock-product', params: { id: props.row.id }})"
                        >
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                        variant="gradient-success"
                        class="btn-icon"
                        v-b-toggle href="#update-stock-sidebar" @click.prevent @click="showStock(props.row)"
                        >
                        <feather-icon icon="Edit2Icon" />
                        
                    </b-button></b-row> -->
             </span>
            <span v-else-if="props.column.field === 'action'">
            <span>
                <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                >
                <template v-slot:button-content>
                    <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                    />
                </template>

                <b-dropdown-item  >
             <b-button 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    v-b-toggle href="#example-sidebar" @click.prevent @click="showUpdate(props.row)">
                    <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"                
                    />
                    <span>Modifier</span>
             </b-button>
                    
                </b-dropdown-item>
                <b-dropdown-item>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                     @click="DeleteProduct(props.row.id)"
                    >
                    <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                    
                    />
                    <span>Supprimer</span>
                    </b-button>
                </b-dropdown-item>
           
                </b-dropdown>
            </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
            {{ props.formattedRow[props.column.field] }}
            </span>
        </template>

        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                Showing 1 to
                </span>
                <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> of {{ props.total }} entries </span>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>

    </b-card>
  
  <div>
      <b-sidebar 
    id="update-stock-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Modifier le Stock
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
            <!-- Update Fournisseur -->
        <b-form-group
          label="REF Produit"
          label-for="produitref"
        >
          <b-form-input
            id="produitref"
            v-model="form2.ref_prod"
            trim
            placeholder="#PO5892"
            disabled
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Nom Produit"
          label-for="produitname"
        >
          <b-form-input
            id="produitname"
            v-model="form2.Nom"
            trim
            placeholder="Ordinateur"
            disabled
          />
        </b-form-group>

        
        <b-form-group
          label="Prix d'achat"
          label-for="Prix_Achat"
        >
          <b-form-input
            id="Prix_Achat"
            v-model="form2.Prix_Achat"
            trim
            placeholder="250 Dhs"
            type="number"
            disabled
          />
        </b-form-group>

        <b-form-group
          label="Prix de vente"
          label-for="prixvente"
          
        >
          <b-form-input
            id="prixvente"
            v-model="form2.Prix_Vente"
            trim
            placeholder="400 Dhs"
            type="number"
            disabled
          />
        </b-form-group>

         <b-form-group
          label="Qunatité"
          label-for="quantite"
        >
         <b-form-spinbutton
        id="demo-sb"
        v-model="form2.quantite"
        min="1"
        max="1000000"
      />
          <!-- <b-form-input
            id="quantite"
            v-model="form.quantite"
            trim
            placeholder="40"
            type="number"
          /> -->
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="AddStock()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
</b-sidebar>

    <b-sidebar 
    id="example-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Modifier Ce Produit
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
            <!-- Update Fournisseur -->
        <b-form-group
          label="Fournisseur"
          label-for="fourni"
        >
          <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
            v-model="fourniID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fournis"
            :clearable="false"
            input-id="fourni"
            label="NomSociete"
          />
        </b-form-group>
        <!-- Product Name -->
        <b-form-group
          label="REF Produit"
          label-for="produitref"
        >
          <b-form-input
            id="produitref"
            v-model="form.ref_prod"
            trim
            placeholder="#PO5892"
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Nom Produit"
          label-for="produitname"
        >
          <b-form-input
            id="produitname"
            v-model="form.Nom"
            trim
            placeholder="Ordinateur"
          />
        </b-form-group>
        <b-form-group
          label="Catégorie"
          label-for="category"
        >
          <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
            v-model="categoryID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :clearable="false"
            input-id="category"
            label="name"
           
          />
        </b-form-group>
        
        <b-form-group
          label="Prix d'achat"
          label-for="Prix_Achat"
        >
          <b-form-input
            id="Prix_Achat"
            v-model="form.Prix_Achat"
            trim
            placeholder="250 Dhs"
            type="number"
          />
        </b-form-group>

        <b-form-group
          label="Prix de vente"
          label-for="prixvente"
        >
          <b-form-input
            id="prixvente"
            v-model="form.Prix_Vente"
            trim
            placeholder="400 Dhs"
            type="number"
          />
        </b-form-group>

         <b-form-group
          label="Qunatité"
          label-for="quantite"
        >
          <b-form-input
            id="quantite"
            v-model="form.quantite"
            trim
            placeholder="40"
            type="number"
          />
        </b-form-group>

         <b-form-group
          label="TVA "
          label-for="tva"
        >
        <select class="form-control" style="margin-bottom: 20px;" 
            v-model="form.tva">
                  <option value="7">7%</option>
                  <option value="10">10%</option>
                  <option value="14">14%</option>
                  <option value="20">20%</option>
        </select>
          <!-- <b-form-input
            id="tva"
            v-model="form.tva"
            trim
            placeholder="10%"
            type="number"
          /> -->
        </b-form-group>
       
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="UpdateProduct()"
          >
            Modifier
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="Reset()"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
    <b-sidebar 
    id="addstock-sidebar"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajouter Nouveau Stock
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata"
        class="p-2"
        @submit.prevent
      >
            <!-- Update Fournisseur -->
            <b-form-group
          label="Produit"
          label-for="fourni"
        >
          <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
            v-model="ProductID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="products"
            :clearable="false"
            input-id="fourni"
            label="Nom"
          />
        </b-form-group>
        <b-form-group
          label="REF Produit"
          label-for="produitref"
        >
          <b-form-input
            id="produitref"
            v-model="ProductID.ref_prod"
            trim
            placeholder="#PO5892"
            disabled
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Nom Produit"
          label-for="produitname"
        >
          <b-form-input
            id="produitname"
            v-model="ProductID.Nom"
            trim
            placeholder="Ordinateur"
            disabled
          />
        </b-form-group>

        
        <b-form-group
          label="Prix d'achat"
          label-for="Prix_Achat"
        >
          <b-form-input
            id="Prix_Achat"
            v-model="ProductID.Prix_Achat"
            trim
            placeholder="250 Dhs"
            type="number"
            disabled
          />
        </b-form-group>

        <b-form-group
          label="Prix de vente"
          label-for="prixvente"
          
        >
          <b-form-input
            id="prixvente"
            v-model="ProductID.Prix_Vente"
            trim
            placeholder="400 Dhs"
            type="number"
            disabled
          />
        </b-form-group>

         <b-form-group
          label="Qunatité"
          label-for="quantite"
        >
         <b-form-spinbutton
        id="demo-sb"
        v-model="ProductID.quantite"
        min="1"
        max="1000000"
      />

        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="NewStock()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>

   <b-sidebar 
    id="sidebar-add-product"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right 
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Nouveau Produit
        </h5>
<!-- {{countries}} -->
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form id="formdata2"
        class="p-2"
        @submit.prevent
      >
            <!-- Fournisseur -->
        <b-form-group
          label="Fournisseur"
          label-for="fourni"
        >
          <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
            v-model="fourniID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fournis"
            :clearable="false"
            input-id="fourni"
            label="NomSociete"
          />
        </b-form-group>
        <!-- Product Name -->
        <b-form-group
          label="REF Produit"
          label-for="produitref"
        >
          <b-form-input
            id="produitref"
            v-model="RefProduct"
            trim
            placeholder="#PO5892"
          />
        </b-form-group>
        
        <!-- Product Name -->
        <b-form-group
          label="Nom Produit"
          label-for="produitname"
        >
          <b-form-input
            id="produitname"
            v-model="NameProduct"
            trim
            placeholder="Ordinateur"
          />
        </b-form-group>
         <b-form-group
          label="Catégorie"
          label-for="category"
        >
          <v-select style="border: 1px solid #0000002b;  border-radius: 0.357rem;"
            v-model="categoryID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :clearable="false"
            input-id="category"
            label="name"
          />
        </b-form-group>
        
        <b-form-group
          label="Prix d'achat"
          label-for="prixAchat"
        >
          <b-form-input
            id="prixAchat"
            v-model="prixAchat"
            trim
            placeholder="250 Dhs"
            type="number"
          />
        </b-form-group>

        <b-form-group
          label="Prix de vente"
          label-for="prixvente"
        >
          <b-form-input
            id="prixvente"
            v-model="prixVente"
            trim
            placeholder="400 Dhs"
            type="number"
          />
        </b-form-group>

         <b-form-group
          label="Qunatité"
          label-for="qnt"
        >
          <b-form-input
            id="qnt"
            v-model="qnt"
            trim
            placeholder="40"
            type="number"
          />
        </b-form-group>

         <b-form-group
          label="TVA "
          label-for="tva"
        >
          <select class="form-control" style="margin-bottom: 20px;" 
            v-model="tva" placeholder="10%">
                  <option value="7">7%</option>
                  <option value="10">10%</option>
                  <option value="14">14%</option>
                  <option value="20">20%</option>
        </select>
        </b-form-group>
       
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="addProduct()"
          >
            Ajouter
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="Reset()"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
  </div>

     <!-- <sidebar-add-product /> -->
    </div>
    <div v-else>
    <not-authoriz></not-authoriz>
    </div>
</template>

<script>
import config from '@/config'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard,BRow, BCol,BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, 
  BDropdownItem,BTooltip,BButton,VBToggle,BCollapse,BSidebar,BForm,BFormSpinbutton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
 import SidebarAddProduct from './SidebarAddProduct.vue'
import vSelect from 'vue-select'
import NotAuthoriz from '../NotAuthoriz.vue'


export default {
      directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
      BCard,BCollapse,
    BCardCode,BRow, BCol,BFormSpinbutton,
    VueGoodTable,
    BAvatar,vSelect,BSidebar,BForm,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BButton,
    SidebarAddProduct,NotAuthoriz
  },
  data() {
    return {
      
          perms:[],
          path:config.path,
          loading: false,
          fournis:[],
          products:[],
          categories:[],
          categoryID:{'id':0,'name':''},
           fourniID:{},
           ProductID:{},
           cateID:0,
          form :new Form({    
          id:0,
          Nom:'',
          ref_prod:'',
          Prix_Achat:'',
          Prix_Vente:'',
          quantite:'',
          tva:'',
          }),
          form2 :new Form({    
          id:0,
          Nom:'',
          ref_prod:'',
          Prix_Achat:'',
          Prix_Vente:'',
          quantite:'',
          tva:'',
          }),
          
          showSide:true,
          pageLength: 10,
          dir: false,
          columns: [
              {
              label: 'R-E-F',
              field: 'ref_prod',
              filterOptions: {
                enabled: true,
                placeholder: 'N° facture.. ',
              },
            },
            {
              label: 'Fournisseur',
              field: 'NomSociete',
              filterOptions: {
                enabled: true,
                placeholder: 'Chercher Nom',
              },
            },
            {
              label: 'Produit',
              field: 'Nom',
              filterOptions: {
                enabled: true,
                placeholder: 'Chercher TotalHT',
              },
            },
            {
              label: 'Catégorie',
              field: 'category',
              filterOptions: {
                enabled: true,
                placeholder: 'Chercher category',
              },
            },
            {
              label: 'Prix Achat',
              field: 'Prix_Achat',
              filterOptions: {
                enabled: true,
                placeholder: 'Chercher TotalNet',
              },
            },
            {
              label: 'Prix Vente',
              field: 'Prix_Vente',
              filterOptions: {
                enabled: true,
                placeholder: 'Chercher tva',
              },
            },
            {
              label: 'Quantité',
              field: 'quantite',
              filterOptions: {
                enabled: true,
                placeholder: 'Chercher reglemment',
              },
            },
            {
              label: 'TVA %',
              field: 'tva',
              filterOptions: {
                enabled: true,
                placeholder: 'Chercher Date',
              },
            },
            {
              label: 'Stock',
              field: 'stock',
            },
            
            {
              label: 'Action',
              field: 'action',
            },
          ],
          rows: [],
          searchTerm: '',
          UserID:{},

          Products:[],
          NameProduct:'',
          RefProduct:'',
          prixAchat:'',
          prixVente:'',
          qnt:'',
          tva:'',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        cheque : 'light-success',
        ESPECE     : 'light-warning',
        CHEQUE : 'light-success',
        espece     : 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
        this.perms=JSON.parse(localStorage.getItem('access'));
        this.getProduct()
        this.$http.get(`${config.API_BASE_URL}/api/auth/getAllFournis`).then(res => { this.fournis = res.data })
        this.$http.get(`${config.API_BASE_URL}/api/auth/getCategories`).then(res => { this.categories = res.data })
        this.UserID=JSON.parse(localStorage.getItem('userData'))
  },
  methods:{
      showStock(com){
        this.form2.reset();
        this.form2.fill(com);
      },
    getProduct(){
       this.loading = true
        this.$http.get(`${config.API_BASE_URL}/api/auth/getProducts`).then(res => { 
          this.rows = res.data;this.products = res.data
           this.loading = false
          })
      },
    showUpdate(com){
        // console.log(com)
        this.form.reset();
        this.form.fill(com);
        this.fourniID.id=com.fournis_id
        this.categoryID.id=com.id_category
        this.categoryID.name=com.category

        },
    Reset(){
        document.getElementById("formdata").reset();
      },
             addProduct(){
            let data = new FormData;
          data.append('UserID', this.UserID.id);
          data.append('fournis_id', this.fourniID.id);
          data.append('ref_prod', this.RefProduct);
          data.append('Nom', this.NameProduct);
          data.append('category',this.categoryID.id);
          data.append('Prix_Achat', this.prixAchat);
          data.append('prix_Vente', this.prixVente);
          data.append('quantite', this.qnt);
          data.append('tva', this.tva);
          // console.log(Object.fromEntries(data))
         this.$http.post(`${config.API_BASE_URL}/api/auth/createproduct`,data)
             .then(res => { 
                if(res.data=='success'){
                  document.getElementById("formdata2").reset();
                    this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Votre produit est bien ajoutée',
                  showConfirmButton: false,
                  timer: 1000 })
                  this.getProduct();
                  // this.$router.go('/')
                }else{
                      this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
              })
                } })
      },
    UpdateProduct(){
        let data= new FormData;
          data.append('UserID', this.UserID.id);
          data.append('fournis_id', this.fourniID.id);
          data.append('ref_prod', this.form.ref_prod);
          data.append('Nom', this.form.Nom);
          data.append('id_category', this.categoryID.id);
          data.append('Prix_Achat', this.form.Prix_Achat);
          data.append('prix_Vente', this.form.Prix_Vente);
          data.append('quantite', this.form.quantite);
          data.append('tva', this.form.tva);
          // console.log(Object.fromEntries(data))
        this.$http.post(`${config.API_BASE_URL}/api/auth/Editproduct/`+ this.form.id,data) .then(res => {   
            if(res.data=='success'){
              // document.getElementById("formdata").reset();
                this.getProduct()
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Produit est bien modifier',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        }) 
        },
    DeleteProduct(id){
      let data= new FormData;
      data.append('UserID', this.UserID.id);
      this.$swal.fire({
          text: "Vous voulez supprimer ce produit ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Oui, supprimer!'
        }).then((result) => {
          if (result.value) {
              this.$http.post(`${config.API_BASE_URL}/api/auth/Deleteproduct/${id}`,data)
                .then(res=> {
                      if(res.data=='Success'){
                      this.$swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Produit est bien supprimé',
                      showConfirmButton: false,
                      timer: 1500  })   
                      this.getProduct(); 
                    }else if(res.data=='error'){
                        this.$swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Vous pouvez pas supprimer ce produit, Déjà affecter à une facture et/ou Bon d\'avoir. '
                        });
                    }    
                }).catch(() => {
                    this.$swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: 'Something went wrong! Pleaz try again'
                        });
                })
            }             
        }) 
    },
    NewStock(){
        let data= new FormData;
        data.append('UserID', this.UserID.id);
        data.append('quantite', this.ProductID.quantite);
        this.$http.post(`${config.API_BASE_URL}/api/auth/EditStock/${this.ProductID.id}`,data) .then(res => {   
            if(res.data=='Success'){
                this.getProduct()
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Stock Produit est bien sauvegardé',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            // console.log("Error......")
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })  
    },
        AddStock(){
        let data= new FormData;
        data.append('UserID', this.UserID.id);
        data.append('quantite', this.form2.quantite);
        this.$http.post(`${config.API_BASE_URL}/api/auth/EditStock/${this.form2.id}`,data) .then(res => {   
            if(res.data=='Success'){
                this.getProduct()
                this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Stock  est bien modifier',
                showConfirmButton: false,
                timer: 1000 })
                }      
        })
        .catch(() => {
            // console.log("Error......")
            this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong! Pleaz try again'
                })
        })  
        },
  }
}
</script>
<style scoped>
[dir] .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    border: 1px solid #0000002b;
    border-radius: 0.357rem;
}
a.btn.btn-icon.btn-gradient-info.collapsed {

    margin-right: 6px;
    margin-left: 8px;
}
[dir] .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    border: 1px solid #0000002b;
    border-radius: 0.357rem;
}
.vs__dropdown-toggle {
    background: none;
    border: 1px solid #0000004f;
    border-radius: 0.357rem;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
